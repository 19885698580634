@import 'src/styles/toolkit';

.application-step-container {
    &.demographics-step-container {
        .application-form {
            .military-status {
                .unset {
                    border: unset;
                    
                    background-color: unset;
                }
            }
            .demographics-info-container {
                @include flex-center-start();

                margin-bottom: 20px;

                .auth-title {
                    &.demographics-info-title {
                        margin: 0;
                    }
                }

                i {
                    margin-left: 15px;

                    color: map-get($jmj-blue, 700);
                }
            }
        }
    }
}

.popup {
    &.popup-container {
        &.demographics-popup {
            min-width: 500px;

            padding: 20px 30px;

            background-color: white;
            box-shadow: none;

            color: map-get($jmj-grey, 600);
        }
    }
}

@include respond-to(tablet) {
    .application-step-container {
        &.demographics-step-container {
            width: 100%;

            .application-form {
                width: 100%;

                margin: 0;

                .form-step {
                    > .nested{
                        width: 100%;

                        margin-left: 0;
                    }

                    > .field {
                        .military-status {
                            .choice-answers {
                                align-items: unset;
                            }
                        }

                        > label {
                            width: 100%;
                            min-width: 0;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile) {
    .application-step-container {
        &.demographics-step-container {
            .application-form {
                .form-step {
                    > .field {
                        .military-status {
                            .choice-answers {
                                align-items: unset;

                                font-size: 18px;
                                line-height: 1.5;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-portrait) {
    .assets-concierge {
        margin-bottom: 50px;
    }

    .application-step-container {
        &.demographics-step-container {
            padding-bottom: 50px;

            .application-form {
                .form-step {
                    > .field {
                        > label {
                            width: 100%;
                            min-width: 0;
                        }

                        .input {
                            input {
                                width: 100%;
                                max-width: unset;
                            }
                        }

                        .selection {
                            width: 100%;
                        }

                        .military-status {
                            .choice-answers {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny-landscape) {
    .application-step-container {
        &.demographics-step-container {
            &.demographics-step-container {
                .application-form {
                    .form-step {
                        > .field {
                            .input {
                                input {
                                    width: 100%;
                                    max-width: unset;
                                }
                            }

                            .selection {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
