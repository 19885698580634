@import 'src/styles/toolkit';

.ui.dimmer.loader-overlay {
    height: 100%;

    background-color: rgba(0,0,0,0.5);

    .loader-container {
        position: absolute;
        top: 0; left: 0;
        @include resize(100vw, 100vh);
    }

    &.is-light {
        background-color: transparent;

        .content {
            .ui.text.loader {
                color: map-get($jmj-grey, 1000);

                &:after {
                    border-color: map-get($jmj-grey, 850) transparent transparent;
                }
            }
        }
    }

    &.is-dark {
        background-color: rgba(0,0,0,0.7);
    }

    &.is-fixed {
        position: fixed;
    }

    &.is-modal {
        .loader-container {
            position: absolute;
            bottom: 0; left: 0;
            width: 100%;

            margin: auto;
        }
    }
}
