@import 'src/styles/toolkit';

.ui.steps.progress-bar-stepper {
    @include flex-start();

    width: 100%;

    border: none;

    .step {
        height: 22px;

        padding: 0;
        border: none;

        &:after {
            display: none;
        }

        .content {
            $offset: -42px;

            position: relative;
            width: 100%;

            .title,
            .description {
                position: absolute;
                right: 0; left: 0;
                line-height: 15px;

                margin: 0 auto;

                font-size: 13px;
                text-align: center;
            }

            .title {
                top: $offset;

                color: map-get($jmj-grey, 400);
                font-weight: normal;
            }

            .description {
                bottom: $offset;

                color: map-get($jmj-grey, 450);
                font-weight: 500;
            }
        }

        &,
        &:hover {
            background-color: map-get($jmj-grey, 350);
        }

        &.active {
            &,
            &:hover {
                background-color: map-get($jmj-grey, 375);
            }

            .content {
                .title {
                    color: map-get($jmj-grey, 600);
                }
            }
        }

        &.completed {
            $completed-color: map-get($jmj-blue, 500);

            &,
            &:hover {
                background-color: $completed-color;
            }

            .content {
                .title {
                    color: $completed-color;
                }
            }

            &.active {
                $active-completed-color: map-get($jmj-blue, 600);

                &,
                &:hover,
                .content {
                    background-color: $active-completed-color;
                }

                .content {
                    .title {
                        color: $active-completed-color;
                    }
                }
            }
        }

        &.has-error {
            $error-color: map-get($jmj-accent, orange);

            &,
            &:hover {
                background-color: $error-color;
            }

            .content {
                .title {
                    color: $error-color;
                }
            }
        }
    }
}

.circular-progress-bar-container {
    display: none;
}

@include respond-to(tablet) {
    .ui.steps.progress-bar-stepper {
        display: none;
    }

    .circular-progress-bar-container {
        @include flex-end-spaced();
        max-width: 500px;

        .CircularProgressbar-path {
            stroke: map-get($jmj-blue, 500);
        }

        .CircularProgressbar-trail {
            stroke: map-get($jmj-grey, 300);
        }

        .circular-progress-bar {
            @include resize(212px);
        }

        .active-step-title {
            max-width: 75%;
            line-height: 1.2;

            color: map-get($jmj-blue, 700);
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
        }

        .steps-completed {
            line-height: 1;

            margin-top: $offset;
            margin-bottom: -$offset;

            color: map-get($jmj-grey, 400);
            font-size: 14px;
            text-align: center;

            &.is-complete {
                color: map-get($jmj-blue, 700);
            }
        }

        .progress-bar-button {
            @include flex-center();
            flex-direction: column;
            width: 120px;

            margin: 0 0 10px;

            &,
            &:hover {
                background: none;
            }

            i.icon {
                margin: 0 0 $offset !important;

                background: map-get($jmj-blue, 700);
                box-shadow: none;
                opacity: 1 !important;

                color: white;
                font-size: 32px;
            }

            span {
                display: block;
                min-height: 28px;

                color: map-get($jmj-grey, 375);
                font-size: 12px;
                font-weight: normal;
                text-transform: uppercase;
            }
        }

        .progress-bar-button-container {
            @include flex-start-spaced();
        }
    }
}

@include respond-to(tablet-portrait) {
    .circular-progress-bar-container {
        max-width: 450px;

        .circular-progress-bar {
            @include resize(175px);
        }

        .active-step-title {
            font-size: 16px;
        }

        .steps-completed {
            font-size: 12px;
        }
    }
}

@include respond-to(mobile) {
    .circular-progress-bar-container {
        .circular-progress-bar {
            @include resize(125px);
        }

        .active-step-title {
            font-size: 12px;
        }

        .steps-completed {
            margin-top: $offset-half;

            font-size: 10px;
        }

        .progress-bar-button {
            width: 80px;

            margin-bottom: 0;

            i.icon {
                margin: 0 0 $offset-half !important;

                font-size: 26px;
            }

            span {
                font-size: 10px;
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .circular-progress-bar-container {
        max-width: 362px;
    }
}

@include respond-to(mobile-wide-landscape) {
    .circular-progress-bar-container {
        max-width: 362px;

        .circular-progress-bar {
            @include resize(125px);
        }

        .active-step-title {
            font-size: 12px;
        }

        .steps-completed {
            margin-top: $offset-half;

            font-size: 10px;
        }

        .progress-bar-button {
            width: 80px;

            margin-bottom: 0;

            i.icon {
                margin: 0 0 $offset-half !important;

                font-size: 26px;
            }

            span {
                font-size: 10px;
            }
        }
    }
}

@include respond-to(tiny) {
    .circular-progress-bar-container {
        .circular-progress-bar {
            @include resize(110px);
        }

        .active-step-title {
            font-size: 10px;
        }

        .progress-bar-button {
            width: 76px;

            i.icon {
                font-size: 24px;
            }

            span {
                font-size: 9px;
            }
        }
    }
}

//Once the tiny-landscape media query has been merged delete the line below and uncomment the (tiny-landscape) query.
@media only screen and (max-width: 568px) and (orientation: landscape) {
// @include respond-to(tiny-landscape) {
    .circular-progress-bar-container {
        max-width: 320px;
    }
}
