@import 'src/styles/toolkit';


.visible.transition {
    &.progress-overlay-container {
        position: absolute;
        top: 0; left: 0;
        display: flex !important;
        justify-content: center;
        align-items: center;
        @include resize(100%);
        z-index: 10000;

        margin: 0;
        padding: $offset-double;

        background-color: map-get($jmj-grey, dimmer);
    }

    .ui.progress {
        &.progress-overlay {
            width: 100%;

            .bar {
                background-color: map-get($jmj-accent, orange);

                .progress {
                    color: white;
                }
            }

            .label {
                margin-top: $offset;

                color: white;
            }
        }
    }
}
