form {
    &.auth-form,
    &.application-form {
        margin-top: 35px;

        .error-container {
            max-width: $form-width;
            line-height: 18px;

            margin-bottom: 20px;

            color: white;
            font-weight: 500;
        }

        .auth-field {
            input {
                width: 302px;
                line-height: 22px;

                margin-bottom: 20px;
                padding: 12px 18px 15px;

                color: map-get($jmj-grey, 600);
                font-size: 18px;
                font-weight: 500;

                &::placeholder {
                    color: map-get($jmj-grey, 400);
                }
            }

            &.error {
                input {
                    border-color: map-get($jmj-grey, 400);

                    background-color: map-get($jmj-accent, orange);

                    &::placeholder {
                        color: white;
                    }
                }
            }
        }
    }
}

@include respond-to(mobile) {
    form {
        &.auth-form,
        &.application-form {
            .auth-field {
                display: block;

                input {
                    width: 100%;
                    min-width: 228px;
                }
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    form {
        &.auth-form,
        &.application-form {
            .auth-field {
                display: inline-flex;

                input {
                    width: 302px;
                }
            }
        }
    }
}
