@import '../../../styles/toolkit';

.popup {
    &.auth-popup {
        min-width: 500px;

        padding: 20px 30px;

        background-color: white;
        box-shadow: none;

        color: map-get($jmj-grey, 600);

        h2 {
            margin-bottom: $offset-half;
        }
    }
}

.loan-application-page {
    .application-step-container {
        &.auth-and-consent {
            .auth-question-container {
                margin-bottom: 125px;

                &.econsent {
                    margin-bottom: 45px;
                }

                .title-container {
                    @include flex-center-start();

                    i {
                        margin: 0 15px;

                        color: map-get($jmj-blue, 700);

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .auth-checkbox {
                    @include resize(18px);
                }

                [class*="auth-checkbox"] {
                    @include flex-start();

                    font-size: 24px;

                    input {
                        width: 55px;
                    }

                    label {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@include respond-to(tablet) {
    .loan-application-page {
        .application-step-container {
            &.auth-and-consent {
                .auth-question-container {
                    width: 65vw;

                    .title-container {
                        .title {
                            margin: 55px 0;

                            font-size: 30px;
                        }
                    }

                    .auth-checkbox-container {
                        > label {
                            font-size: 20px;
                        }
                    }
                }

                .application-step-footer {
                    max-width: 100%;
                }
            }
        }
    }
}

@include respond-to(mobile) {
    .loan-application-page {
        .application-step-container {
            &.auth-and-consent {
                .auth-question-container {
                    width: 100%;

                    line-height: 1.1;

                    br {
                        display: none;
                    }

                    .title-container {
                        justify-content: space-between;

                        .title {
                            width: 100%;
                            max-width: 500px;

                            font-size: 24px;
                        }
                    }

                    .field {
                        .auth-checkbox-container {
                            .auth-checkbox {
                                min-width: 55px;

                                margin-top: 3px;
                            }

                            > label {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .loan-application-page {
        .application-step-container {
            &.auth-and-consent {
                .application-step-footer {
                    flex-direction: row;

                    .error-container {
                        order: unset;

                        margin-bottom: 0;

                        h3 {
                            font-size: 14px;
                        }
                    }

                    button {
                        width: 90px;

                        margin: 0;
                    }
                }
            }
        }
    }
}
