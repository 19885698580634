@import 'src/styles/toolkit';

$item-blue: map-get($jmj-blue, 700);
$sub-offset: 9px;
$margin-bottom: 20px;
$item-border: 1px solid map-get($jmj-grey, 250);

.dash-item {
    @include flex-center-spaced();
    @include resize(100%, 79px);

    margin-bottom: $margin-bottom;
    padding: 0 $offset 0 26px;
    border: $item-border;

    &.is-clickable {
        &,
        * {
            cursor: pointer;
        }
    }

    &.has-sub-item {
        &,
        * {
            cursor: pointer;
        }

        &.expanded {
            margin-bottom: 0;
        }
    }

    &.has-action,
    &.has-sub-details {
        margin-bottom: 0;
    }

    &.has-details {
        padding-right: 16px;

        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.03);
    }

    &.is-loan {
        .dash-item-name {
            .dash-name {
                color: map-get($jmj-grey, 400);
            }
        }

        &.is-active {
            .dash-item-name {
                .dash-name {
                    color: $item-blue;
                }
            }

            .dash-detail-container {
                .dash-detail {
                    i.icon,
                    label {
                        color: $item-blue;
                    }
                }
            }
        }
    }

    &.is-loading {
        position: relative;

        .ui.loader {
            $offset: 14px;

            display: block;
            right: 0; left: unset;

            transform: translateX(-$offset) translateY(-50%);
        }
    }

    .dash-name,
    .dash-progress-counter {
        line-height: 28px;

        font-size: 24px;
        font-weight: 400;
    }

    .dash-item-name {
        @include flex-start();
        flex-direction: column;

        .is-clickable {
            &:hover {
                cursor: pointer;
            }
        }

        .dash-name {
            color: $item-blue;
            text-transform: capitalize;
        }

        .dash-subtext {
            line-height: 15px;

            color: map-get($jmj-grey, 400);
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .dash-progress-counter {
        margin-right: 7px;

        color: map-get($jmj-grey, 500);
        font-style: italic;
    }


    .dash-detail-container {
        @include flex-center-end();
        width: 50%;

        .dash-detail {
            width: 50%;

            padding: 0 5px;

            &:last-child {
                text-align: right;
            }

            &.is-clickable {
                &,
                * {
                    @include ellipsis();

                    color: map-get($jmj-grey, 400);
                    cursor: pointer;
                }
            }

            i.icon {
                margin-right: $sub-offset;

                font-size: 15px;
            }

            label {
                line-height: 18px;

                color: map-get($jmj-grey, 400);
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
    }

    &.is-v2 {
        @include flex-center-start();

        border-color: map-get($jmj-grey, 225);

        &,
        &.expanded {
            margin-bottom: 4px;
        }

        &.has-sub-item {
            &,
            * {
                cursor: default;
            }
        }

        .tasks-remaining,
        .completed-icon {
            margin-right: $offset;
        }

        .tasks-remaining {
            @include flex-center();
            @include resize(23px);
            line-height: 15px;

            border-radius: map-get($border-radius, round);

            background-color: map-get($jmj-accent, orange);

            color: white;
            font-size: 12px;
            font-weight: 800;
        }

        .dash-item-name {
            .dash-name {
                font-style: italic;
                font-weight: 400;
            }
        }

        .dash-progress-counter {
            display: none;
        }
    }
}

.dash-sub-item-container {
    margin-bottom: $margin-bottom;

    .dash-sub-item {
        @include flex-center-spaced();
        @include resize(calc(100% - #{$sub-offset}), 46px);

        margin-left: $sub-offset;
        padding: 0 30px 0 18px;
        border: $item-border;

        background-color: map-get($jmj-grey, 225);

        &:first-child {
            border-top: none;
        }

        &:last-child {
            margin-bottom: $margin-bottom;
        }

        &.has-actions {
            &,
            * {
                cursor: pointer;
            }
        }

        .name-container {
            @include flex-center-start();

            i.icon {
                &.angle {
                    @include resize(1.18em);

                    margin: 0 6px 0 0;

                    font-size: 16px;
                }
            }

            label {
                line-height: 15px;

                color: $item-blue;
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
            }
        }

        .tasks-remaining {
            @include flex-center();
            @include resize(23px);
            line-height: 15px;

            border-radius: map-get($border-radius, round);

            background-color: map-get($jmj-accent, orange);

            color: white;
            font-size: 12px;
            font-weight: 800;
        }

        + .dash-item {
            margin-bottom: 0;
        }

        &.is-v2 {
            @include resize(100%, auto);
            min-height: 38px;

            margin-left: 0;
            border: none;

            &.has-actions {
                &,
                * {
                    cursor: default;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            .name-container {
                max-width: 20%;

                margin-left: 10px;

                .icon {
                    display: none;
                }

                label {
                    max-width: 100%;

                    @include ellipsis();
                }
            }

            &.is-mobile {
                .name-container {
                    .icon {
                        display: block;
                    }
                }
            }
        }
    }
}

.dash-action-container {
    @include flex-center-spaced();
}

.dash-sub-detail-container {
    display: flex;
    width: 75%;
}

.dash-action-container,
.dash-sub-detail-container {
    margin: 0 0 $margin-bottom $sub-offset;

    &.dash-sub-item-action-container {
        margin-bottom: 0;
    }

    .dash-action {
        @include flex-center();
        flex: 1;
        @include resize(25%, 46px);

        padding: 0 $offset-half;
        border: $item-border;
        border-top: none;

        background-color: $item-blue;

        &,
        * {
            cursor: pointer;
        }

        i.icon {
            margin-right: $sub-offset;

            &.upload {
                font-size: 15px;
            }

            &.check {
                font-size: 20px;
            }
        }

        label {
            line-height: 12px;

            color: white;
            font-size: 10px;
            font-weight: 500;
            text-transform: uppercase;

            @include ellipsis();

            .label-text {
                @include ellipsis();
            }
        }

        &.is-completed {
            background-color: map-get($jmj-grey, 450);

            &,
            * {
                cursor: default;
            }
        }
    }

    &.is-v2 {
        @include flex-center-end();
        flex-wrap: wrap;
        width: 75%;

        margin: -38px 0 0 25%;

        .dash-action {
            flex: none;
            height: 31px;
            width: calc(25% - #{$offset-half});
            min-width: 200px;

            margin: $offset-quarter;
            padding: 0 $offset-double;
            border-radius: 22px;

            box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);

            i.icon {
                $height: 15px;

                height: $height;
                line-height: $height;
            }

            &.is-completed {
                border: 1px solid $item-blue;

                background-color: white;

                &,
                * {
                    cursor: default;
                }

                label,
                i.icon {
                    color: $item-blue;
                }
            }

            &.is-rejected {
                border: 1px solid map-get($jmj-accent, orange);

                label,
                i.icon {
                    color: map-get($jmj-accent, orange);
                }
            }
        }
    }
}

.dash-sub-item-and-action-wrapper {
    &.is-v2 {
        height: auto;
        overflow: hidden;

        margin-bottom: $offset-quarter;

        background-color: map-get($jmj-grey, 225);
    }
}

@include respond-to(tablet) {
    .dash-sub-item-container {
        .dash-sub-item {
            &.is-v2 {
                .name-container {
                    margin-left: 0;
                }
            }
        }
    }

    .dash-action-container,
    .dash-sub-detail-container {
        &.is-v2 {
            .dash-action {
                $width: percentage(1/3);

                width: calc(#{$width} - #{$offset-half});
            }
        }
    }
}

@include respond-to(mobile) {
    .dash-item {
        .dash-item-name {
            .dash-name {
                letter-spacing: -0.5px;
            }
        }
    }

    .dash-sub-item-container {
        .dash-sub-item {
            &.is-v2 {
                .name-container {
                    max-width: 100%;
                }
            }
        }
    }

    .dash-action-container,
    .dash-sub-detail-container {
        &.is-v2 {
            width: 100%;

            margin-top: 0;
            margin-left: 0;

            &.dash-sub-item-action-container {
                margin-bottom: $offset;
            }

            .dash-action {
                width: calc(100% - #{$offset-half});
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .dash-action-container,
    .dash-sub-detail-container {
        &.is-v2 {
            .dash-action {
                width: calc(50% - #{$offset-half});
            }
        }
    }
}
