@import '../../../styles/toolkit';

@include respond-to(tablet) {
    .application-step-container {
        &.declarations {
            .application-form {
                width: 100%;

                .form-step {
                    > .field {
                        label {
                            min-width: 100%;
                        }

                        &.nested {
                            label {
                                min-width: 100%;
                            }

                            .bankruptcy {
                                max-width: 25%;
                            }

                            .discharge-date-container {
                                min-width: 300px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-portrait) {
    .application-step-container {
        &.declarations {
            .application-form {
                .form-step {
                    .nested {
                        margin-left: 0;

                        .selection {
                            min-width: 100%;
                        }

                        textarea {
                            width: unset;
                        }

                        .date-picker {
                            .field {
                                width: 100%;

                                .input {
                                    width: 100%;
                                }
                            }
                        }

                        .input {
                            input {
                                max-width: unset;
                            }
                        }

                        .checkbox-group {
                            .bankruptcy {
                                flex-direction: column;
                                max-width: 129px;

                                label {
                                    margin-bottom: 20px;
                                }

                                .discharge-date-container {
                                    flex-direction: column;
                                    min-width: 150px;

                                    margin-left: 28px;

                                    span {
                                        margin-bottom: 20px;

                                        font-size: 16px;
                                    }

                                    .date-picker {
                                        margin-right: 0;

                                        .field {
                                            input {
                                                margin: 0 0 20px 12px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .button {
                    margin-left: 0;

                    &.nested {
                        @include flex-start;
                        justify-content: center;

                        width: 100%;
                    }
                }
            }
        }
    }
}

@include respond-to(tiny) {
    .application-step-container {
        &.declarations {
            .application-form {
                .form-step {
                    > .field {
                        label {
                            font-size: 22px;
                        }
                    }

                    .nested {
                        label {
                            font-size: 18px;
                        }

                        .checkbox-group {
                            .bankruptcy {
                                .discharge-date-container {
                                    margin-left: 13px;
                                }
                            }
                        }
                    }
                }

                .application-step-footer {
                    .error-container {
                        font-size: 12px;
                    }

                }
            }
        }
    }
}

//Once the tiny-landscape media query has been merged delete the line below and uncomment the (tiny-landscape) query.
@media only screen and (max-width: 568px) and (orientation: landscape) {
// @include respond-to(tiny-landscape) {
    .application-step-container {
        &.declarations {
            .application-form {
                width: 100%;

                margin-bottom: 45px;

                .form-step {
                    > .field {
                        label {
                            min-width: 100%;

                            font-size: 22px;
                        }
                    }

                    .nested {
                        label {
                            font-size: 18px;
                        }

                        .bankruptcy {
                            max-width: 25%;
                        }

                        .discharge-date-container {
                            flex-direction: column;
                            min-width: 300px;

                            span {
                                margin-bottom: 20px;
                                padding-top: 5px
                            }

                            .date-picker {
                                .field {
                                    input {
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                }

                .application-step-footer {
                    .error-container {
                        max-width: 230px;

                        font-size: 10px;
                    }
                }
            }
        }
    }
}
