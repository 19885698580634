@import 'src/styles/toolkit';

.assets-concierge {
    .title-instructions {
        font-weight: normal;
    }
}

@include respond-to(tablet) {
    .assets-concierge {
        margin-bottom: 50px;
    }

    .application-step-container {
        &.assets-step-container {
            width: 100%;

            .title {
                margin: 45px 0;
            }

            .application-form {
                width: 100%;

                margin: 0;

                &.asset-type-selection-form {
                    margin-top: $offset-double;
                }

                .form-step {
                    > .field {
                        > label {
                            width: 100%;
                            min-width: 0;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile) {
    .application-step-container {
        &.assets-step-container {
            .assets-form-container {
                .section-title {
                    margin-bottom: $offset-double;
                }
            }
        }
    }
}

@include respond-to(mobile-portrait) {
    .assets-concierge {
        .choice-form {
            .question-container {
                .form-step {
                    .field {
                        .answer-container {
                            .icon {
                                &.right {
                                    display: none;
                                }
                            }

                            .choice-answers {
                                all: unset;
                                
                                line-height: 1.5;

                                .icon {
                                    padding-top: 2px;
                                }
                            }
                        }
                    }
                }

                button {
                    width: 100%;
                }
            }
        }
    }

    .application-step-container {
        &.assets-step-container {
            padding-bottom: 50px;

            .application-form {
                &.asset-type-selection-form {
                    .form-step {
                        > .field {
                            > .dropdown {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .assets-form-container {
                .application-form {
                    .form-step {
                        > .field {
                            .input {
                                input {
                                    width: 100%;
                                    max-width: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny-landscape) {
    .application-step-container {
        &.assets-step-container {
            &.assets-step-container {
                .application-form {
                    .form-step {
                        > .field {
                            .input {
                                input {
                                    width: 100%;
                                    max-width: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
