$mobile-small: 320px;
$mobile-portrait: 480px;
$tiny-landscape: 568px;
$mobile-landscape: 600px;
$tablet-portrait: 768px;
$tablet-landscape: 1024px;
$desktop: 1366px;
$desktop-large: 1440px;
$desktop-xlarge: 1600px;

@mixin respond-to($media) {
    @if $media == tiny {
        @media only screen
        and (max-width: $mobile-small)
        { @content; }
    }
    @if $media == tiny-landscape {
        @media only screen
        and (max-width: $tiny-landscape)
        and (orientation: landscape)
        { @content; }
    }
    @else if $media == mobile {
        @media only screen
        and (max-width: $tablet-portrait - 1px)
        { @content; }
    }
    @else if $media == mobile-portrait {
        @media only screen
        and (max-width: $mobile-landscape - 1px)
        and (orientation: portrait)
        { @content; }
    }
    @else if $media == mobile-landscape {
        @media only screen
        and (min-width: $mobile-landscape)
        and (max-width: $tablet-portrait - 1px)
        { @content; }
    }
    // iPhone X/11/12 Landscape
    @else if $media == mobile-wide-landscape {
        @media only screen
        and (min-width: 375px)
        and (max-width: 823px)
        and (-webkit-min-device-pixel-ratio: 3)
        and (orientation: landscape)
        { @content; }
    }
    @else if $media == tablet {
        @media only screen
        and (max-width: $desktop)
        { @content; }
    }
    @else if $media == tablet-portrait {
        @media only screen
        and (min-width: $tablet-portrait)
        and (max-width: $tablet-landscape - 1px)
        { @content; }
    }
    @else if $media == tablet-landscape {
        @media only screen
        and (min-width: $tablet-landscape)
        and (max-width: $desktop)
        { @content; }
    }
}
