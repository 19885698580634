.task-label-popup {
    .verbose-label {
        text-transform: capitalize;
    }
}

@include respond-to(tablet) {
    body {
        .popup {
            &.popup-container {
                &.is-mobile,
                &.is-tablet {
                    $width: 80vw;

                    width: $width;
                    // Needed to override semantic defaults
                    min-width: $width;
                    max-width: $width;
                }
            }
        }
    }
}
