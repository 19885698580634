@import 'src/styles/toolkit';

@include respond-to(tablet) {
    .loan-application-page {
        .application-step-container {
            &.residences-container {
                width: 100%;

                .application-form {
                    width: 100%;

                    margin: 45px 0;

                    .form-step {
                        > .field {
                            .choice-form {
                                height: 30vh;
                            }

                            > label {
                                width: 100%;
                                min-width: 0;
                            }

                            .selection {
                                min-width: 150px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tablet-landscape) {
    .loan-application-page {
        .application-step-container {
            &.residences-container {
                .application-form {
                  .form-step {
                      > .field {
                          .choice-form {
                              height: 15vh;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile) {
    .loan-application-page {
        .application-step-container {
            &.residences-container {
                .application-form {
                    > button {
                        width: 100%;
                    }

                    .subtitle {
                      margin-bottom: 50px;
                    }

                    .address-container {
                      margin-bottom: 32px;

                        .form-step {
                            .field {
                                width: 100%;

                                margin-bottom: 0;

                                > .address-fields {
                                    flex-direction: column;
                                    align-items: unset;

                                    .residence-input,
                                    .unit-input,
                                    .city-input,
                                    .zip-code-input {
                                        margin: 13px 0;

                                        input {
                                            min-width: 100%;
                                        }
                                    }

                                    .state-input {
                                        width: 100%;

                                        margin-top: 13px;
                                    }
                                }
                            }
                        }
                    }

                    .form-step {
                        label {
                            min-width: 100%;

                            font-size: 22px;
                        }

                        h1 {
                            font-size: 30px;
                            letter-spacing: -0.5px;

                            margin: 45px 0;
                        }

                        > .field {
                            .selection {
                                width: 100%;
                                align-items: unset;

                            }

                            .field-with-error-container {
                                display: block;
                            }

                            .time-at-residence {
                                flex-direction: column;

                                .selection {
                                    max-width: 100%;

                                    margin: 13px 0;
                                }
                            }

                            input {
                                width: 100%;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .loan-application-page {
        .application-step-container {
            &.residences-container {
                .application-form {
                    margin-top: 0;

                    button {
                        width: 150px;
                    }

                    .address-container {
                        .form-step {
                            .field {
                                > .address-fields {
                                    flex-direction: row;
                                    align-items: center;

                                    label {
                                      display: none;
                                    }

                                    .residence-input {
                                        width: 360px;

                                        margin-right: 10px;
                                    }

                                    .city-input {
                                        input {
                                            width: 200px;
                                        }
                                    }

                                    .state-input {
                                        max-width: 150px;

                                        margin-bottom: 13px;
                                        margin-left: 10px;
                                    }

                                    .zip-code-input {
                                        width: 150px;

                                        margin-bottom: 6.75px;
                                    }
                                }
                            }
                        }
                    }

                    .form-step {
                        > .field {
                            .choice-form {
                                height: 15vh;
                            }
                            
                            > label {
                                width: 100%;
                                min-width: 0;
                            }

                            .selection {
                                max-width: 200px;
                            }

                            .time-at-residence {
                                flex-direction: row;

                                .selection {
                                    max-width: 150px;

                                    margin: 13px 5px;
                                }
                            }

                            input {
                                max-width: 300px;
                            }
                        }
                    }

                    .application-step-footer {
                        display: flex;
                        flex-direction: row;
                        min-width: 100%;

                        button {
                            width: 100px;
                        }

                        .error-container {
                            order: unset;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-wide-landscape) {
    .loan-application-page {
        .application-step-container {
            &.residences-container {
                .application-form {
                    margin-top: 0;

                   .form-step {
                       > .field {
                           .title {
                               height: 45vh;
                               line-height: 1.2;

                               margin-bottom: 0;

                               font-size: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Landscape mode on Andrew's phone (iPhone 5/SE)
@include respond-to(tiny-landscape) {
    .loan-application-page {
        .application-step-container {
            &.residences-container {
                .application-form {
                    margin-top: 0;

                    .form-step {
                        .field {
                            .title {
                                height: 15vh;
                                
                                font-size: 24px;
                            }

                            .selection {
                                width: 516px;
                            }

                            .field-with-error-container {
                                max-width: 516px;
                            }
                        }
                    }

                    .address-container {
                        .form-step {
                            .field {
                                > .address-fields {
                                    .residence-input,
                                    .city-input,
                                    .zip-code-input {
                                        min-width: 100%;
                                    }

                                    .state-input {
                                        min-width: 100%;

                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny) {
    .loan-application-page {
        .application-step-container {
            &.residences-container {
                .application-form {
                    margin-top: 0;

                    .form-step {
                        .field {
                            .title {
                                font-size: 24px;
                            }
                        }
                    }
                    
                    .application-step-footer {
                        .error-container {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
