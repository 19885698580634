@import 'src/styles/toolkit';

.asset-worksheet-concierge {
    max-width: 1090px;
}

.asset-worksheet-form {
    margin-top: 0;

    h1.title {
        margin: 0 0 60px;
    }
}

.application-step-container {
    &.assets-worksheet-table {
        td {
            text-transform: capitalize;
        }
    }
}
