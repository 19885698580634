@import 'src/styles/toolkit';

.ui {
    &.modal {
        &.default-modal {
            &.disclosure-modal {
                @include resize(90vw, 90vh);
                max-width: 1092px;

                #helloSignContainer {
                    height: calc(100% - 150px);
                }

                .modal-footer {
                    @include flex-center-end();

                    margin-top: $offset-double;
                }
            }
        }
    }

    &.dimmer {
        .content {
            .loader-container {
                position: unset;
            }
        }
    }
}

@include respond-to(tablet-portrait) {
    .ui {
        &.modal {
            &.default-modal {
                &.disclosure-modal {
                    width: 90%;
                }
            }
        }
    }
}

@include respond-to(mobile) {
    .ui {
        &.modal {
            &.default-modal {
                &.disclosure-modal {
                    width: 90%;
                    max-height: 90%;
                }
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .ui {
        &.modal {
            &.default-modal {
                &.disclosure-modal {
                    height: unset;
                    max-height: unset;
                }
            }
        }
    }
}

@include respond-to(tiny-landscape) {
    .ui {
        &.modal {
            &.default-modal {
                &.disclosure-modal {
                    height: unset;
                    max-height: unset;
                }
            }
        }
    }
}
