@import "src/styles/toolkit";

// Styles used for LandingPage, Login, ForgotPassword, PasswordReset, Registration
.landing-container {
    @include flex-start();

    padding: 0 map-get($container-padding, horizontal);

    .landing-content {
        width: 490px;

        .title,
        .subtitle {
            color: white;
        }

        .title {
            margin: 0 0 20px;
        }

        .subtitle {
            margin: 0;
        }

        .auth-form {
            margin-top: 35px;

            .error-container {
                display: inline-block;

                padding: 5px;
                max-width: $form-width;

                background: map-get($jmj-accent, orange);

                color: map-get($jmj-grey, 200);
                font-size: 14px;
                font-weight: 600;

                &:empty {
                    display: none;
                }
            }

            .input > input {
                padding-left: 0;

                border: none;
                border-bottom: 0.5px solid map-get($jmj-grey, 375);
                border-radius: 0;

                background: none;

                color: white;
            }

            .error > input {
                &::placeholder {
                    color: map-get($jmj-accent, orange);
                }
            }

            h3 {
                color: map-get($jmj-grey, 225);
            }

            .ui.list {
                .content.val {
                    &.true,
                    &.false {
                        margin: 5px 0;

                        color: map-get($jmj-grey, 225);
                        font-size: 15px;
                        font-weight: bold;
                    }
                }
            }

        }

        .landing-controls {
            margin-top: 55px;

            &.form-controls {
                margin-top: 35px;
            }

            .landing-button,
            .auth-button,
            .inverted {
                &:focus-within {
                    box-shadow: 0 0 0 2px white;
                }
            }

            .landing-button {
                margin-right: 20px;
            }
        }
    }

    .landing-graphic {
        @include resize(42.4vw, 70.9vh);

        margin-left: 6.8vw;

        background: transparent url("../assets/images/landing-phone.png")
            no-repeat center / contain;
    }
}

@include respond-to(tablet) {
    .landing-container {
        padding: 0 map-get($container-padding, tablet-horizontal);
    }
}

@include respond-to(mobile) {
    .landing-container {
        padding: 0 map-get($container-padding, mobile-horizontal);

        .landing-content {
            width: 100%;
        }

        .landing-graphic {
            display: none;
        }
    }
}

@include respond-to(mobile-landscape) {
    .landing-container {
        .landing-graphic {
            display: block;
        }
    }
}
