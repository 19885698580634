@import 'src/styles/toolkit';

.application-step-container {
    &.employment-and-income-step-container {
        .income-form-container {
            .application-form {
                .form-step {
                    .additional-income {
                        display: flex;

                        margin-bottom: 100px;

                        .field {
                            &.nested {
                                display: flex;
                                flex-direction: column;

                                margin: 20px 20px 20px 0;

                                input {
                                    margin-top: 15px;
                                    padding-left: 0;
                                    @include bottom-grey();
                                }

                                label {
                                    color: map-get($jmj-grey, 450);
                                    font-size: 20px;
                                }
                            }
                        }
                    }

                    .monthly-average {
                        @include flex-center-spaced();
                        width: 400px;

                        margin: 10px 0;

                        text-transform: capitalize;

                        label {
                            line-height: 20px;

                            color: map-get($jmj-grey, 400);
                        }
                    }

                    label {
                        color: map-get($jmj-grey, 900)
                    }
                }
            }
        }

        .application-form {
            .form-step {
                .field {
                    .radio-group {
                        &.previous-employment {
                            display: flex;
                            flex-direction: column;
                            padding-left: 20px;
                        }

                        .ui.radio {
                            label {
                                color: map-get($jmj-grey, 400);
                            }

                            &.checked {
                                label {
                                    color: map-get($jmj-blue, 800);
                                }
                            }
                        }

                        &.radio-error {
                            .ui.radio {
                                &.has-error {
                                    label {
                                        color: red;
                                    }
                                }
                            }
                        }
                    }

                    .payment-types-container {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        width: 500px;

                        .radio-group {
                            display: flex;
                            @include resize(500px, 55px);

                            .ui.radio {
                                width: 50%;
                                
                                label {
                                    color: map-get($jmj-grey, 400);
                                }

                                &.checked {
                                    label {
                                        color: map-get($jmj-blue, 800);
                                    }
                                }
                            }

                            &.radio-error {
                                .ui.radio {
                                    &.has-error {
                                        label {
                                            color: red;
                                        }
                                    }
                                }
                            }
                        }

                        .payment-options-container {
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;
                            @include resize(100%, 150px);

                            .payment-types {
                                width: 50%;

                                margin: 10px 0;

                                border-radius: 5px;

                                label {
                                    font-size: 23px;

                                    text-transform: capitalize;

                                }

                                input[type="checkbox"] {
                                    appearance: none;
                                    @include resize(0);
                                }

                                .checked {
                                    color: map-get($jmj-blue, 800);
                                }

                                .none {
                                    color: map-get($jmj-grey, 400);
                                }

                                .has-error {
                                    color: red;
                                }

                                &:hover {
                                    label {
                                        cursor: pointer;

                                        &.disabled {
                                            cursor: default;
                                        }
                                    }
                                }

                                &:focus-within {
                                    box-shadow: 0 0 0 2px map-get($jmj-blue, 800);
                                }

                            }
                        }
                    }
                }
            }

            .button-container {
                display: flex;

                .message-container {
                    margin: 10px 35px;

                    color: red;
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }
    }
}

.choice-form {
    color: white;

    &.employment-and-income,
    &.assets-form {
        @include flex-start();

        margin-top: 50px;

        .container{
            @include flex-center();
            width: 100vw;

            margin: auto;
        }

        .question-container {
            @include flex-start();
            flex-direction: column;

            .title-instructions {
                margin-bottom: 50px;

                font-size: 36px;
            }

            .form-step {
                margin-bottom: 35px;

                .field {
                    color: white;

                    .choice-container {
                        .answer-container {
                            @include flex-center-spaced();
                            position: relative;

                            .choice-answers {
                                margin: 10px auto 10px 0;
                                padding: 10px 25px;
                                border-bottom: 3px solid rgba(255, 255, 255, 0);

                                font-size: 20px;
                                font-weight: normal;

                                transition: ease-in-out 0.2s;

                                &.selected {
                                    color: map-get($jmj-blue, 600);
                                    font-weight: bold;
                                }

                                &:hover {
                                    cursor: pointer;
                                }

                                input[type="checkbox"] {
                                    appearance: none;

                                    margin-right: 10px;
                                    border-radius: 5px;
                                }

                                &.none-selected {
                                    opacity: .5;
                                }
                            }

                            .unselected {
                                position: absolute;
                                height: auto;

                                visibility: hidden;
                            }

                            &:hover,
                            &:focus-within {
                                .icon {
                                    visibility: visible;
                                }
                            }

                            .selected {
                                position: absolute;
                                height: auto;
                            }

                            &.disabled {
                                .choice-answers {
                                    &:hover {
                                        cursor: default;
                                    }
                                }

                                .icon {
                                    visibility: hidden;
                                }
                            }
                        }
                    }
                }
            }

            button {
                background: map-get($jmj-accent, orange);

                color: white;

                &:focus {
                    background-color: transparent;
                    box-shadow: 0 0 0 2px map-get($jmj-accent, orange) inset;
                }
            }
        }
    }
}

.employment-and-income-concierge {
    .title-instructions {
        font-weight: normal;
    }
}

@include respond-to(tablet) {
    .employment-and-income-concierge {
        margin-bottom: 50px;

        .choice-form {
            .question-container {
                .form-step {
                    .field {
                        .title-instructions {
                            font-size: 30px;
                            letter-spacing: -0.5px;
                        }

                        .answer-container {
                            .choice-answers {
                                line-height: 1.4;

                                .icon {
                                    padding-top: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .application-step-container {
        &.employment-and-income-step-container {
            .application-form {
                width: 100%;

                margin: 0;

                &.owner-type-selection-form {
                    margin-top: $offset-double;
                }

                .form-step {
                    > .field {
                        &.nested {
                            .input {
                                input {
                                    margin-bottom: 45px;
                                }
                            }

                            .date-picker {
                                margin-bottom: 0;

                                .field {
                                    .icon {
                                        height: 40%;
                                    }
                                }
                            }
                        }

                        > label {
                            width: 100%;
                            min-width: 0;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-portrait) {
    .employment-and-income-concierge {
        .choice-form {
            .question-container {
                .form-step {
                    .field {
                        .answer-container {
                            .icon {
                                &.right {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                button {
                    width: 100%;
                }
            }
        }
    }

    .application-step-container {
        &.employment-and-income-step-container {
            width: 100%;

            padding-bottom: 50px;

            .application-form {
                .button-container {
                    &.additional-sources {
                        button {
                            width: 100%;
                        }
                    }
                }

                .form-step {
                    .field {
                        .payment-types-container {
                            width: 100%;

                            .radio-group {
                                width: 100%;

                                margin-left: 0;
                            }

                            .payment-options-container {
                                width: 100%;

                                margin-left: 0;
                            }
                        }

                        .monthly-average {
                            display: flex;
                            flex-direction: column;
                            align-items: start;

                            margin: 20px 0;

                            .input {
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }

            .title {
                margin: 45px 0;
            }

            .form-fields-container {
                margin-left: 0;

                .application-form {
                    width: 100%;

                    margin: 0;

                    .form-step {
                        > .nested {
                            margin: 0;
                        }

                        > .field {
                            > .dropdown {
                                width: 100%;
                                max-width: unset;
                            }

                            > label {
                                width: 100%;
                                min-width: 0;
                            }

                            > .date-picker {
                                .field {
                                    width: 100%;

                                    .input {
                                        width: 100%;
                                    }

                                    i {
                                        margin-right: 1px;
                                    }
                                }
                            }

                            .input {
                                input {
                                    width: 100%;
                                    max-width: unset;
                                }
                            }
                        }

                        .additional-income {
                            flex-direction: column;

                            .field {
                                &.nested {
                                    margin-right: 0;
                                }
                            }
                        }
                    }

                    .address-container {
                        .form-step {
                            > .field {
                                margin-bottom: 0;

                                .search {
                                    width: unset;
                                }

                                > .address-fields {
                                    flex-direction: column;
                                    align-items: unset;

                                    .residence-input,
                                    .unit-input,
                                    .city-input,
                                    .state-input,
                                    .postal-input {
                                        margin-right: unset;
                                        margin-bottom: 30px;

                                        input {
                                            min-width: unset;
                                        }
                                    }
                                }

                                &.bottom {
                                    margin-bottom: 45px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny-landscape) {
    .application-step-container {
        &.employment-and-income-step-container {
            width: 100%;

            padding-bottom: 50px;

            .title {
                margin: 45px 0;
            }

            .form-fields-container {
                .application-form {
                    width: 100%;

                    margin: 0;

                    .form-step {
                        > .nested {
                            width: calc(100% - 32px);
                        }

                        > .field {
                            > .dropdown {
                                width: 100%;
                                max-width: unset;
                            }

                            > label {
                                width: 100%;
                                min-width: 0;
                            }

                            > .date-picker {
                                .field {
                                    width: 100%;

                                    .input {
                                        width: 100%;
                                    }

                                    i {
                                        margin-right: 1px;
                                    }
                                }
                            }

                            .input {
                                input {
                                    width: 100%;
                                    max-width: unset;
                                }
                            }
                        }

                        .additional-income {
                            flex-direction: column;

                            .field {
                                &.nested {
                                    margin-right: 0;
                                }
                            }
                        }
                    }

                    .address-container {
                        .form-step {
                            > .field {
                                margin-bottom: 0;

                                .search {
                                    width: unset;
                                }

                                > .address-fields {
                                    flex-direction: column;
                                    align-items: unset;

                                    .residence-input,
                                    .unit-input,
                                    .city-input,
                                    .state-input {
                                        min-width: 100%;

                                        margin-right: 0;
                                        margin-bottom: 30px;
                                    }

                                    .zip-code-input {
                                        min-width: 100%;
                                    }
                                }

                                &.bottom {
                                    margin-bottom: 45px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny) {
    .application-step-container {
        &.employment-and-income-step-container {
            .application-form {
                .form-step {
                    .field {
                        .payment-types-container {
                            .radio-group {
                                .radio {
                                    label {
                                        font-size: 20px;
                                    }
                                }
                            }

                            .payment-options-container {
                                .payment-types {
                                    label {
                                        font-size: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
