// Element resize
@mixin resize($width, $height: $width) {
  width: $width;
  height: $height;
}

// Flex
@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-center-spaced() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-center-start() {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-center-end() {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-start() {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex-start-center() {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@mixin flex-start-spaced() {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin flex-end() {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@mixin flex-end-spaced() {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

// Border Radius
@mixin border-top-radius($radius: 0) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin border-bottom-radius($radius: 0) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius: 0) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-right-radius($radius: 0) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

// Border size and color
@mixin bottom-grey() {
  border: none;
  border-bottom: 1px solid map-get($jmj-grey, 400);
  border-radius: 0
}

// Text
@mixin ellipsis {
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin add-sibling-comma($margin-right: 0, $margin-left: 0) {
    &:not(:last-of-type) {
        &:after {
            display: inline-block;
            content: ', ';

            margin: 0 $margin-right 0 $margin-left;
        }
    }
}

@mixin button-link-tab($margin: 0 15px, $padding: 10px 0) {
    line-height: 15px;

    margin: $margin;
    padding: $padding;

    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;

    cursor: pointer;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &,
    &:hover {
        color: map-get($jmj-grey, 400);
    }

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 0.6;
    }

    &.active,
    &.is-active {
        $active-blue: map-get($jmj-blue, 700);

        box-shadow: 0 1px 0 0 $active-blue;

        color: $active-blue;
    }

    &.inverted {
        &,
        &:hover {
            color: white;
        }

        &.is-active {
            $active-grey: map-get($jmj-grey, 400);

            box-shadow: 0 1px 0 0 $active-grey;

            color: $active-grey;
        }
    }

    @include respond-to(tiny) {
        letter-spacing: -0.5px;
    }
}
