@import 'src/styles/toolkit';

.application-step-container {
    &.loan-details-container {
        .application-form {
            .form-step {
                > .field {
                    .realtor {
                        margin-top: 20px;

                        &.first {
                            margin-top: 35px
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tablet) {
    .application-step-container {
        &.loan-details-container {
            width: 100%;

            .application-form {
                width: 100%;

                margin: 0;

                .form-step {
                    > .field {
                        > label {
                            width: 100%;
                            min-width: 0;
                        }

                        .realtor {
                            margin-top: 20px;

                            &.first {
                                margin-top: 35px
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to (mobile-portrait) {
    .application-step-container {
        &.loan-details-container {
            .application-form {
                .form-step {
                    > .field {
                        > .dropdown {
                            width: 100%;
                            max-width: unset;
                        }

                        > .future-date {
                            flex-direction: column;
                            align-items: flex-start;

                            .date-picker {
                                width: 100%;

                                .field {
                                    width: 100%;
    
                                    .input {
                                        width: 100%;
                                    }
    
                                    i {
                                        margin-right: 1px;
                                    }
                                }
                            }

                            .error-message {
                                margin: 10px 0 0;
                            }
                        }

                        .input {
                            input {
                                width: 100%;
                                max-width: unset;
                            }
                        }

                        .field-with-error-container {
                            flex-direction: column;
                            align-items: unset;

                            .error-message {
                                margin: 10px 0 0;
                            }
                        }
                    }

                    .address-container {
                        .form-step {
                            > .field {
                                margin-bottom: 0;

                                .search {
                                    width: unset;
                                }

                                > .address-fields {
                                    flex-direction: column;
                                    align-items: unset;

                                    .residence-input,
                                    .unit-input,
                                    .city-input,
                                    .state-input,
                                    .postal-input {
                                        margin-right: unset;
                                        margin-bottom: 30px;

                                        input {
                                            min-width: unset;
                                        }
                                    }
                                }

                                &.bottom {
                                    margin-bottom: 45px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $mobile-landscape) and (orientation: landscape) {
    .application-step-container {
        &.loan-details-container {
            .application-step-footer {
                .error-container {
                    max-width: 45%;
                }
            }
        }
    }
}

@include respond-to(tiny-landscape) {
    .application-step-container {
        &.loan-details-container {
            .application-form {
                .form-step {
                    .field {
                        > .future-date {
                            flex-direction: column;
                            align-items: flex-start;

                            .date-picker {
                                width: 100%;

                                .field {
                                    width: 100%;
    
                                    .input {
                                        width: 100%;
                                    }
    
                                    i {
                                        margin-right: 1px;
                                    }
                                }
                            }

                            .error-message {
                                margin: 10px 0 0;
                            }
                        }

                        .selection {
                            min-width: 100%;
                        }
                    }

                    input {
                        min-width: 100%;
                    }

                    .address-container {
                        .form-step {
                            > .field {
                                margin-bottom: 0;

                                > .address-fields {
                                    flex-direction: column;

                                    .residence-input,
                                    .unit-input,
                                    .city-input,
                                    .state-input {
                                        min-width: 100%;

                                        margin-right: 0;
                                        margin-bottom: 30px;

                                        input {
                                            min-width: 100%;
                                        }
                                    }

                                    .zip-code-input {
                                        min-width: 100%;
                                    }
                                }

                                &.bottom {
                                    margin-bottom: 45px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
