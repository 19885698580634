@import 'src/styles/toolkit';

.invoice-page {
    $payment-container-padding: 28px 30px 30px;
    padding: 0 map-get($container-padding, horizontal);

    .invoice-container,
    .payment-container {
        width: 486px;

        padding: $payment-container-padding;
        border: 1px solid map-get($jmj-grey, 400);
        border-radius: map-get($border-radius, card);

        box-shadow: 2px 15px 20px 0 map-get($jmj-grey, 100);

        .invoice-title {
            display: block;
            line-height: 22px;

            margin-bottom: 32px;

            color: map-get($jmj-grey, 425);
            font-size: 18px
        }
    }

    .invoice-container {
        margin-bottom: 30px;

        .invoice-due-date,
        .invoice-number {
            line-height: 27px;

            color: map-get($jmj-grey, 425);
            font-size: 22px;
        }

        .invoice-header {
            padding-bottom: 9px;
            border-bottom: 2px solid map-get($jmj-grey, 300);

            span {
                display: block;
            }

            .invoice-amount {
                line-height: 46px;

                margin-bottom: 2px;

                color: map-get($jmj-grey, 600);
                font-size: 38px;
                font-weight: 700;
            }

            .invoice-due-date {
                line-height: 27px;

                color: map-get($jmj-grey, 425);
                font-size: 22px;

                &.invoice-overdue {
                    color: red;
                }
            }
        }

        .invoice-context {
            padding: 30px 0;

            .context-row {
                &:not(:last-child) {
                    margin-bottom: 9px;
                }

                label,
                span {
                    display: inline-block;
                    line-height: 19px;

                    font-size: 16px;
                }

                label {
                    min-width: 66px;

                    color: map-get($jmj-grey, 425);
                }

                span {
                    color: map-get($jmj-grey, 600);
                }
            }
        }

        .invoice-charges {
            .invoice-number {
                display: block;

                padding-bottom: 9px;
                border-bottom: 2px solid map-get($jmj-grey, 300);
            }

            .charge-row {
                @include flex-center-spaced();

                padding: 9px 0;
                border-bottom: 1px solid map-get($jmj-grey, 300);

                label,
                span {
                    display: inline-block;
                    line-height: 15px;

                    color: map-get($jmj-grey, 600);
                    font-size: 12px;
                    font-weight: 700;
                }

                &.total-row {
                    label,
                    span {
                        color: black;
                    }

                    label {
                        text-transform: capitalize;
                    }
                }
            }
        }

        .invoice-footer {
            margin-top: 9px;

            span,
            a {
                line-height: 15px;

                font-size: 12px;
            }

            span {
                color: map-get($jmj-grey, 425);
            }

            a {
                color: map-get($jmj-blue, 700);
            }
        }
    }

    .payment-container {
        position: relative;

        .section-title {
            display: block;
            line-height: 22px;

            margin-bottom: 30px;

            color: map-get($jmj-grey, 600);
            font-size: 18px;

            &.no-bottom-margin {
                margin-bottom: 0;
            }
        }

        .payment-field-container {
            margin-bottom: 30px;
            border: 1px solid map-get($jmj-grey, 325);
            border-radius: map-get($border-radius, card);

            .payment-row,
            .address-fields {
                position: relative;
                display: flex;
                justify-content: space-evenly;
                align-items: flex-start;

                &:not(:last-child) {
                    border-bottom: 1px solid map-get($jmj-grey, 325);
                }
            }

            .payment-field {
                $line-height: 19px;
                $padding: 13px 12px;

                width: 100%;

                & + .payment-field {
                    border-left: 1px solid map-get($jmj-grey, 325);
                }

                > input,
                &.state-input {
                    line-height: $line-height;

                    padding: $padding;
                    border: none;

                    background: none;

                    font-size: 16px;
                }

                &.error {
                    background: map-get($jmj-accent, error-text);
                }

                ::placeholder {
                    color: map-get($jmj-grey, 425);
                }

                &.unit-input {
                    max-width: 25%;
                }

                &.city-input {
                    max-width: 50%;
                }

                &.state-input {
                    border-left: 1px solid map-get($jmj-grey, 325);

                    &,
                    .icon {
                        line-height: $line-height !important;

                        padding: $padding !important;
                    }

                    .default,
                    .icon {
                        color: map-get($jmj-grey, 425);
                    }
                }
            }

            .visa-mastercard-logo {
                position: absolute;
                top: 12px; right: 12px;
                width: 70px;
            }
        }

        .checkbox-container {
            @include flex-center-start();

            margin: 8px 0;

            .primary-checkbox {
                position: relative;
                @include resize(20px);

                margin-right: 10px;

                &:before,
                &:after {
                    display: block;
                    content: '';
                    @include resize(20px);

                    background: white;
                }

                &:after {
                    position: absolute;
                    top: 0; left: 0;
                    line-height: 20px;

                    border: 1px solid map-get($jmj-grey, 325);
                    border-radius: 6px;
                }

                &:checked {
                    &:after {
                        content: '\2713';

                        color: map-get($jmj-blue, 700);
                        font-size: 16px;
                        text-align: center;
                    }
                }

                & + label {
                    line-height: 15px;

                    color: map-get($jmj-grey, 400);
                    font-size: 12px;
                }
            }
        }

        .payment-footer {
            @include flex-center();
            width: 100%;

            button {
                width: 100%;
            }
        }

        .error-message {
            display: block;
            line-height: 16px;

            margin: $offset 0 0;

            color: map-get($jmj-accent, orange);
            font-size: 14px;
        }

        .success-container {
            position: absolute;
            top: 0; left: 0;
            @include flex-center();
            flex-direction: column;
            @include resize(100%);
            z-index: 10;

            padding: $payment-container-padding;
            border-radius: map-get($border-radius, card);

            background: rgba(255, 255, 255, 0.9);

            label {
                display: block;
            }

            .success-message {
                @include flex-center();
                width: 100%;

                margin-bottom: $offset-double;

                label {
                    line-height: 22px;

                    font-size: 18px;
                }

                label,
                .icon {
                    color: green;
                }

                label {
                    margin-right: 10px;
                }

                .icon {
                    font-size: 16px;
                }
            }

            .countdown-container {
                @include flex-center();
                flex-direction: column;
                width: 100%;

                label {
                    line-height: 20px;

                    color: map-get($jmj-grey, 900);
                    font-size: 16px;
                }

                .button-container {
                    @include flex-center-spaced();
                    width: 100%;

                    margin-top: $offset-double;

                    &.has-no-countdown {
                      @include flex-center();
                    }
                }
            }
        }
    }
}

@include respond-to(tablet) {
    .invoice-page {
        padding: $offset map-get($container-padding, tablet-horizontal);
    }
}

@include respond-to(mobile) {
    .invoice-page {
        padding: $offset map-get($container-padding, mobile-horizontal);

        .invoice-container,
        .payment-container {
            width: 100%;
        }

        .payment-container {
            .payment-field-container {
                .payment-field {
                    &.expiration-field,
                    &.cvc-field,
                    &.first-name-field,
                    &.last-name-field {
                        width: 50%;
                    }

                    &.residence-input {
                        max-width: percentage(2/3);
                    }

                    &.unit-input {
                        max-width: percentage(1/3);
                    }

                    &.city-input {
                        max-width: 40%;
                    }

                    &.state-input,
                    &.zip-code-input {
                        max-width: 30%;

                        .zip-code-input {
                          max-width: 100%;
                        }
                    }
                }
            }

            .success-container {
                .success-message {
                    margin-bottom: $offset-triple;
                }

                .countdown-container {
                    label {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
