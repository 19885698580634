@import 'src/styles/toolkit';

.popup.popup-container {
    &.document-preview-popup {
        min-width: auto;

        padding: $offset;

        background-color: white;

        .document-preview {
            width: 100%;
        }
    }
}

.ui {
    &.modal {
        &.download-documents-modal {
            @include flex-center-spaced();

            &.is-downloading {
                .downloads-table-container,
                .downloads-modal-mobile-container {
                    overflow: hidden;
                }
            }

            .modal-subheader {
                text-transform: capitalize;

                &.is-hidden {
                    visibility: hidden;
                }
            }

            .download-icon,
            .preview-icon {
                &:hover {
                    cursor: pointer;
                }
            }

            .download-icon {
                color: map-get($jmj-blue, 700);
            }

            .downloads-table-container,
            .downloads-modal-mobile-container {
                position: relative;
            }

            .downloads-table-container {
                height: 280px;
                overflow-y: auto;

                margin-bottom: 20px;
                padding-right: $offset;

                table {
                    thead {
                        .header-row {
                            > th {
                                position: sticky;
                                top: 0;

                                background: rgba(255, 255, 255, 0.95);

                                color: map-get($jmj-grey, 600);
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                border: none;

                                color: map-get($jmj-grey, 450);
                            }

                            :first-child {
                                width: auto;
                            }

                            .icon {
                                width: auto;

                                margin: 0 0 0 10px;
                            }
                        }
                    }
                }
            }

            .no-documents-container {
                @include flex-start();
                flex-direction: column;

                h3,
                h4 {
                    color: map-get($jmj-grey, 450)
                }

                img {
                    margin: $offset 0;
                }

                h4 {
                    margin-bottom: $offset-double;
                }
            }

            .downloads-modal-mobile-container {
                display: none;
            }

            .download-documents-modal-footer {
                @include flex-center-end();

                .error-message {
                    line-height: 22px;

                    color: map-get($jmj-accent, orange);
                }

                .cancel-button {
                    width: 120px;

                    margin: 0;

                    color: white;
                }

                .download-all-button {
                    width: 172px;

                    margin: 0 0 0 15px;

                    background: map-get($jmj-blue, 700);

                    &,
                    i.icon {
                        color: white;
                    }
                }
            }

            &.rejected-tasks-modal {
                .downloads-table-container {
                    .dismiss-rejection {
                        text-align: center;
                    }
                }
            }
        }
    }
}

@include respond-to(mobile) {
    .ui {
        &.modal {
            &.download-documents-modal {
                $height: 90vh;

                max-height: $height;

                padding: $offset-double 34px;

                &.has-multiple-documents {
                    height: $height;
                }

                .modal-header {
                    line-height: 28px;

                    font-size: 22px;
                }
            }
        }
    }
}

@include respond-to(mobile-portrait) {
    .ui {
        &.modal {
            &.download-documents-modal {
                margin-bottom: 10px;

                &.has-error {
                    .downloads-modal-mobile-container {
                        max-height: calc(100% - 242px);
                    }
                }

                .downloads-table-container {
                    display: none;
                }

                .downloads-modal-mobile-container {
                    display: block;
                    max-height: calc(100% - 192px);
                    overflow-y: auto;

                    margin-bottom: $offset-double;

                    .details-container {
                        @include flex-start();
                        flex-direction: column;

                        margin-bottom: $offset;
                        padding: $offset-double;
                        border: 1px solid map-get($jmj-grey, 300);

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .details {
                            @include flex-start();
                            flex-direction: column;
                            flex-wrap: wrap;
                            width: 100%;

                            margin-bottom: $offset;

                            label,
                            .value {
                                line-height: 18px;

                                margin-bottom: 5px;
                            }

                            label {
                                color: map-get($jmj-grey, 400);
                                font-size: 14px;
                                font-weight: 500;
                            }

                            .value {
                                font-size: 13px;
                                font-weight: bold;
                                text-transform: capitalize;
                            }
                        }

                        .preview-button,
                        .download-button {
                            width: 100%;

                            color: white;

                            i.icon {
                                color: white;
                            }
                        }

                        .preview-button {
                            margin-bottom: $offset-half;

                            background: map-get($jmj-grey, 400);
                        }

                        .download-button {
                            background: map-get($jmj-blue, 700);
                        }
                    }
                }

                .download-documents-modal-footer {
                    flex-direction: column-reverse;

                    .error-message {
                        margin-top: $offset;
                    }

                    .cancel-button,
                    .download-all-button {
                        width: 100%;
                    }

                    .cancel-button {
                        margin: 0;

                        background: map-get($jmj-grey, 400);
                    }

                    .download-all-button {
                        margin: 0 0 $offset;
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .ui {
        &.modal {
            &.download-documents-modal {
                $height: 87vh;

                height: $height;
                max-height: $height;

                .modal-subheader {
                    margin-bottom: $offset;
                }

                .downloads-table-container {
                    height: calc(100% - 122px);
                }
            }
        }
    }
}

@include respond-to(mobile-wide-landscape) {
    .ui {
        &.modal {
            &.download-documents-modal {
                $height: 85vh;

                height: $height;
                max-height: $height;

                padding: $offset-double 34px;

                .modal-header {
                    line-height: 28px;

                    font-size: 22px;
                }

                .modal-subheader {
                    margin-bottom: $offset;
                }

                .downloads-table-container {
                    height: calc(100% - 122px);
                }
            }
        }
    }
}

@include respond-to(tiny) {
    .ui {
        &.modal {
            &.download-documents-modal {
                padding: $offset-double;

                .close-icon {
                    top: $offset !important; right: $offset !important;
                }

                .modal-header {
                    line-height: 26px;

                    font-size: 20px;
                }

                .downloads-modal-mobile-container {
                    .details-container {
                        padding: $offset;
                    }
                }
            }
        }
    }
}

@include respond-to(tiny-landscape) {
    .ui {
        &.modal {
            &.download-documents-modal {
                $height: 83vh;

                height: $height;
                max-height: $height;

                padding: $offset-double;

                .modal-subheader {
                    margin-bottom: $offset;
                }

                .downloads-table-container {
                    height: calc(100vh - 232px);

                    font-size: 13px;

                    .modal-header {
                        line-height: 26px;

                        font-size: 20px;
                    }
                }
            }
        }
    }
}
