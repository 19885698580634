@import 'src/styles/toolkit';

.ui {
    &.task-modal,
    &.dashboard-modal,
    &.welcome-modal,
    &.item-modal {
        position: fixed;
        top: 45%;
        left: 50%;
        @include resize(60%);
        z-index: 110;
        overflow-y: scroll;

        padding: 100px;
        border: none;

        background-color: map-get($jmj-grey, 200);

        transform: translate(-50%, -50%);
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        h3 {
            margin-bottom: 0;

            color: map-get($jmj-grey, 500);

            font-size: 23px;
            font-weight: initial;
        }

        h1 {
            margin-top: 0;
            margin-bottom: 30px;

            color: map-get($jmj-blue, 700);

            font-size: 45px;
            font-weight: 100;

            &.create-loan {
                margin-top: 30px;

                color: map-get($jmj-grades, disclosed);
            }
        }
    }

    &.confirmation-modal {
        .actions {
            background: none;

            button {
                background-color: map-get($jmj-grey, 450);

                &:hover {
                    color: white;
                }

                &.primary {
                    background-color: map-get($jmj-accent, orange);
                }
            }
        }
    }

    &.modals {
        &.dimmer {
            background-color: rgba(57,57,57,0.1);
        }
    }
}

.modal-overlay {
    position: fixed;
    top: 0;left: 0;
    @include resize(100%);
    z-index: 100;

    background-color: map-get($jmj-grey, 350);
    opacity: 0.6;
}

.ui {
    &.modal {
        &.default-modal {
            $border-radius: 23px;

            width: 738px;

            padding: 44px 34px 39px;
            border-radius: $border-radius;

            .close-icon {
                $icon-offset: 19px;

                position: absolute;
                top: $icon-offset; right: $icon-offset;
                @include flex-center();
                @include resize(auto);

                margin: 0;
                padding: $offset;

                color: map-get($jmj-grey, 400);
                font-size: 14px;
            }

            .modal-header,
            .modal-subheader {
                display: block;
            }

            .modal-header {
                line-height: 32px;

                margin-bottom: 8px;

                color: map-get($jmj-blue, 700);
                font-size: 26px;
                font-weight: 500;
            }

            .modal-subheader {
                line-height: 15px;

                margin-bottom: 30px;

                color: map-get($jmj-grey, 450);
                font-size: 12px;
                font-weight: 500;
            }

            .modal-footer {
                position: relative;
                @include flex-center-spaced();

                .error-message {
                    width: 540px;
                    line-height: 22px;

                    color: map-get($jmj-accent, orange);
                }
            }

            .loader-overlay {
                height: 100%;

                border-radius: $border-radius;
            }
        }

        &.reset-password-modal {
            .password-reset-form {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                height: 100%;

                .title {
                    margin-bottom: 0;

                    font-size: 32px;
                }

                .subtitle {
                    &.text-grey {
                        margin-bottom: 20px;

                        .ui.list.instructions-container {
                            display: flex;
                            flex-direction: column;

                            margin-bottom: 0;
                            padding-left: 5px;

                            .content {
                                margin-bottom: 0em;

                                font-size: 14px;
                            }

                            .val {
                                &.true {
                                    color: map-get($jmj-blue, 700);
                                }

                                &.false {
                                    color: red;
                                }
                            }
                        }
                    }
                }

                .modal-content {
                    width: 100%;

                    margin-bottom: 20px;

                    .field {
                        display: flex;
                        width: 100%;

                        &:not(:first-child) {
                            margin: 20px 0;
                        }

                        .input {
                            width: 100%;

                            input {
                                width: 100%;

                                padding-left: 0;
                                @include bottom-grey();
                            }
                        }
                    }
                }

                .modal-footer {
                    display: flex;

                    .message-container {
                        max-width: 300px;

                        color: red;
                        text-align: center;

                        .success-message {
                            color: map-get($jmj-blue, 700);
                        }
                    }

                    .save-button {
                        margin: 0 0 0 5px;

                        background: map-get($jmj-blue, 700);

                        color: white;
                    }
                }
            }
        }

        &.delete-account-modal {
            .modal-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                .modal-content {
                    @include flex-start();
                    flex-direction: column;
                    min-height: 75px;

                    margin-bottom: 40px;
                    padding: 0 20px;

                    label {
                        margin: 10px 0;

                        color: map-get($jmj-grey, 800);
                        font-size: 22px;
                    }

                    .checkbox-container {
                        .checkbox {
                            label {
                                color: map-get($jmj-grey, 800);
                                font-size: 18px;

                                &::after {
                                    color: map-get($jmj-blue, 700);
                                }
                            }
                        }
                    }
                }

                .modal-footer {
                    button {
                        width: 85px;

                        color: white;
                    }

                    .delete-button {
                        width: 160px;
                    }
                }
            }
        }

        &.forgot-password-modal {
            .modal-header {
                margin-bottom: 15px;

                font-size: 30px;
            }

            .modal-subheader {
                line-height: 25px;

                margin-bottom: 22px;

                font-size: 25px;
            }

            .modal-footer {
                justify-content: flex-end;

                button {
                    width: 30%;

                    background: map-get($jmj-accent, orange);

                    color: white;
                }
            }
        }
    }
}

@include respond-to(tablet-portrait) {
    .ui {
        &.modal {
            &.default-modal {
                width: 90%;
            }
        }
    }
}

@include respond-to(mobile) {
    .ui {
        &.modal {
            &.default-modal {
                width: 90%;
                max-height: 90%;

                .close-icon {
                    $offset: 19px;

                    top: $offset !important; right: $offset !important;
                }

                .modal-footer {
                    flex-direction: column;

                    .error-message {
                        max-width: 100%;
                        max-height: 40px;
                        overflow-x: hidden;

                        margin-bottom: 20px;
                    }

                    button {
                        width: 100%;
                    }
                }
            }

            &.reset-password-modal {
                padding: 25px;

                .password-reset-form {
                    width: 100%;

                    .subtitle {
                        &.text-grey {
                            h3 {
                                font-size: 14px;
                            }
                        }
                    }

                    .modal-content {
                        margin-bottom: 0;

                        .field {
                            .input {
                                width: 100%;
                            }
                        }
                    }

                    .modal-footer {
                        flex-direction: column-reverse;

                        .message-container {
                            order: 1;

                            margin-bottom: 30px;
                        }

                        button {
                            margin: 0;
                        }

                        .save-button {
                            margin: 0 0 10px 0;
                        }
                    }
                }
            }

            &.delete-account-modal {
                .modal-content {
                    padding: unset;

                    .checkbox-container {
                        .checkbox {
                            label {
                                font-size: 16px;

                                &::before {
                                    margin-top: 6px;
                                }

                                &::after {
                                    margin-top: 6px;
                                }
                            }
                        }
                    }
                }

                .modal-footer {
                    flex-direction: column-reverse;

                    .delete-button {
                        width: 100%;

                        margin-bottom: 10px;
                    }
                }
            }

            &.forgot-password-modal {
                .modal-header {
                    margin-bottom: 15px;

                    font-size: 21px;
                }

                .modal-subheader {
                    line-height: 25px;

                    margin-bottom: 22px;

                    font-size: 17px;
                }
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .ui {
        &.modal {
            &.default-modal {
                max-height: unset;
            }

            &.reset-password-modal {
                max-height: 350px;

                padding: 25px;

                .password-reset-form {
                    .modal-content {
                        margin: 0;

                        .field {
                            .input {
                                width: 100%;

                                input {
                                    width: 100%;
                                }
                            }

                            &:not(:first-child) {
                                margin: 10px 0;
                            }
                        }
                    }

                    .modal-footer {
                        flex-direction: row;

                        .message-container {
                            margin-bottom: 0;
                        }

                        button {
                            width: unset;
                        }

                        .save-button {
                            order: 3;

                            margin-bottom: 0;
                        }
                    }

                    .subtitle {
                        &.text-grey {
                            margin-bottom: 0;

                            h3 {
                                font-size: 14px;
                            }

                            .ui.list.instructions-container {
                                display: grid;
                                grid-template-columns: 33% 33% 33%;

                                margin-top: 0;
                                padding-left: 0;

                                .content {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }

            &.delete-account-modal {
                height: 90%;
                max-height: 350px;

                .modal-container {
                    .modal-footer {
                        flex-direction: row;

                        button {
                            width: 85px;

                            margin: 0;
                        }

                        .delete-button {
                            width: 160px;
                        }
                    }
                }
            }

            &.forgot-password-modal {
                .modal-footer {
                  @include flex-start();

                    button {
                        width: 30%;

                        background: map-get($jmj-accent, orange);

                        color: white;
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-wide-landscape) {
    .ui {
        &.modal {
            &.reset-password-modal {
                height: 330px;

                padding: 10px 25px;

                .password-reset-form {
                    .modal-content {
                        margin: 0;

                        .field {
                            &:not(:first-child) {
                                margin: 10px 0;
                            }
                        }
                    }

                    .subtitle {
                        &.text-grey {
                            margin-bottom: 0;

                            h3 {
                                margin-bottom: 5px;
                            }

                            .ui.list.instructions-container {
                                display: grid;
                                grid-template-columns: 33% 33% 33%;

                                margin-top: 0;
                                padding-left: 0;

                                .content {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }

            &.delete-account-modal {
                height: 90%;
                max-height: 350px;
            }
        }
    }
}

@include respond-to(tiny) {
    .ui {
        &.modal {
            &.upload-modal {
                .uploaded-files-container {
                    max-height: 100px;
                }
            }

            &.reset-password-modal {
                max-height: 100%;

                .password-reset-form {
                    .title {
                        font-size: 26px;
                    }

                    .subtitle.text-grey {
                        margin-bottom: 5px;

                        h3 {
                            margin-bottom: 0;

                            font-size: 12px;
                        }

                        .ui.list.instructions-container {
                            margin-top: 10px;

                            .content {
                                font-size: 13px;
                            }
                        }
                    }

                    .modal-content {
                        .field {
                            input {
                                width: 100%;

                                padding: 5px 0;
                            }
                        }
                    }

                    .modal-footer {
                        .message-container {
                            margin-bottom: 15px;

                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny-landscape) {
    .ui {
        &.modal {
            &.default-modal {
                max-height: unset;
            }

            &.reset-password-modal {
                width: 100%;
                max-height: 280px;

                padding: 25px;

                .password-reset-form {
                    .title {
                        font-size: 26px;
                    }

                    .modal-content {
                        margin: 0;

                        .field {
                            &:not(:first-child) {
                                margin: 5px 0;
                            }

                            input {
                                width: 400px;
                            }
                        }
                    }

                    .modal-footer {
                        flex-direction: row;
                        margin-top: 5px;

                        .message-container {
                            max-width: 200px;

                            margin-bottom: 0;

                            font-size: 11px;
                        }

                        button {
                            width: unset;
                        }

                        .save-button {
                            order: 3;

                            margin-bottom: 0;
                        }
                    }

                    .subtitle {
                        &.text-grey {
                            margin-bottom: 0;

                            h3 {
                                margin-bottom: 0;

                                font-size: 14px;
                            }

                            .ui.list.instructions-container {
                                display: grid;
                                grid-template-columns: 16% 13% 23% 23% 25%;

                                margin-top: 0;
                                padding-left: 0;

                                .content {
                                    font-size: 10px;
                                    letter-spacing: -.2px;

                                    .x.icon {
                                        margin-right: 1px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.delete-account-modal {
                height: 90%;
                max-height: 350px;

                padding: 25px;

                .modal-container {
                    .modal-footer {
                        flex-direction: row;

                        button {
                            width: 85px;

                            margin: 0;
                       }

                        .delete-button {
                            width: 160px;
                        }
                    }
                }
            }
        }
    }
}
