@import 'src/styles/toolkit';

.application-step-container {
    &.add-co-applicant-form {
        .application-form {
            .form-step {
                &.disabled {
                    label {
                      opacity: 40%;
                    }
                }
            }
            .field {
                h1.title-question {
                    color: white;
                    font-size: 48px;
                    font-weight: normal;
                }

                .radio-group {
                    label {
                        &:hover {
                            color: map-get($jmj-grey, 500);
                        }
                    }

                    [class*="checked"] {
                        label {
                            color: white;

                            transition: .3s;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tablet) {
    .loan-application-page {
        .application-step-container {
            &.add-co-applicant-form {
                .add-remove-table-container {
                    .details-container {
                        .detail {
                            .value {
                                width: calc(100% - #{$offset-half});
        
                                @include ellipsis();
        
                                &.email {
                                    text-transform: lowercase;
                                }
                            }
                        }
                    }
                }

                .application-form {
                    width: 100%;

                    margin: 45px 0;

                    .add-co-applicant {
                        .field {
                            h1 {
                              font-size: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tablet-landscape) {
    .loan-application-page {
        .application-step-container {
            &.add-co-applicant-form {
                .application-form {
                    width: 70%;
                }
            }
        }
    }
}

@include respond-to(mobile) {
    .loan-application-page {
        .application-step-container {
            &.add-co-applicant-form {
                .application-form {
                    margin: 35px 0;

                    .form-step {
                        > .field {
                            .borrower-name {
                                &.co-applicant {
                                    input {
                                      margin: 12px 15px 12px 0;
                                    }
                                }
                            }

                            input {
                                width: 568px;
                                max-width: 568px;
                            }
                        }
                    }

                    .add-co-applicant {
                        .field {
                            h1 {
                              font-size: 25px;
                            }
                        }
                    }
                }
            }
        }
    }

    .ui {
        &.modal {
            &.default-modal {
                &.income-modal {
                    &.co-applicant-modal {
                        .modal-subheader {
                            margin: 0 0 30px;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny) {
    .loan-application-page {
        .application-step-container {
            &.add-co-applicant-form {
                .application-form {
                    .application-step-footer {
                        .error-container {
                            font-size: 9px;
                        }
                    }
                }
            }
        }
    }

    .ui {
        &.modal {
            &.default-modal {
                &.income-modal {
                    &.co-applicant-modal {
                        .modal-header {
                            margin-bottom: 10px;
                        }
                        
                        .modal-subheader {
                            margin-bottom: 5px;
                            padding: 10px 0;
                
                            font-size: initial;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny-landscape) {
    .loan-application-page {
        .application-step-container {
            &.add-co-applicant-form {
                .application-form {
                    .application-step-footer {
                        button {
                            width: 90px;
                        }
                    }
                }
            }
        }
    }

    .ui {
        &.modal {
            &.default-modal {
                &.income-modal {
                    &.co-applicant-modal {
                        .modal-footer {
                            flex-direction: inherit;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-portrait) {
    .loan-application-page {
        .application-step-container {
            &.add-co-applicant-form {
                .application-form {
                    button {
                        width: 100%;
                    }

                    .form-step {
                        > .field {
                            > .dropdown {
                                width: 100%;
                                max-width: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tiny-landscape) {
    .loan-application-page {
        .application-step-container {
            &.add-co-applicant-form {
                .application-form {
                    margin-bottom: 55px;

                    button {
                        width: 100%;
                    }

                    .form-step {
                        > .field {
                            > .dropdown {
                                width: 100%;
                                max-width: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .loan-application-page {
        .application-step-container {
            &.add-co-applicant-form {
                .application-form {
                    > .form-step {
                        .field {
                            h1 {
                                font-size: 30px;
                            }

                            input {
                                width: 150px;
                                max-width: 150px;
                            }
                        }
                    }
                }
            }
        }
    }

    .ui {
        &.modal {
            &.default-modal {
                &.income-modal {
                    &.co-applicant-modal {
                        .modal-footer {
                            flex-direction: inherit;
                        }
                    }
                }
            }
        }
    }
}
