@import 'src/styles/toolkit';

.dashboard-container {
    margin-top: 10px;

    .current-loan-label {
        padding-left: 92px;

        color: map-get($jmj-grey, 400);
        font-size: 13px;
        letter-spacing: .5px;
        text-transform: uppercase;

        .text-italicize {
            font-style: italic;
        }

        .text-bold {
            font-weight: bold;
        }

        .milestone-label {
            margin-left: $offset;
        }
    }

    .section-container {
        $section-offset: 10px;

        @include flex-start();

        margin: 32px 0 0;
        padding: 0 92px;

        .section-column {
            width: calc(50% - #{$section-offset});

            &:first-child {
                margin-right: $section-offset;
            }

            &:last-child {
                margin-left: $section-offset;
            }
        }

        .tasks-container {
            @include flex-start();
            width: 100%;

            .section-column {
                width: calc(100% - #{$section-offset});

                .next-milestone-container {
                    .dash-name{
                        color: map-get($jmj-grey, 450);
                    }
                }

                .realtor-container {
                    position: relative;

                    .dash-item-name {
                        width: 100%;

                        .dash-name {
                            max-width: calc(100% - 100px);

                            @include ellipsis();
                        }
                    }

                    .alternate-button {
                        position: absolute;
                        top: 0; right: 0;
                        width: 80px;

                        margin: 1.5rem;
                    }
                }
            }
        }
    }
}

@include respond-to(tablet) {
    .dashboard-container {
        padding: 0 map-get($container-padding, tablet-horizontal);

        .current-loan-label {
            padding-left: 0;
        }

        .section-container {
            flex-direction: column;

            padding: 0;

            .tasks-container {
                flex-direction: column;

                .section-column {
                    width: 100%;
                }
            }

            .section-column {
                width: 100%;

                &:first-child,
                &:last-child {
                    margin: 0;
                }

                .dash-item {
                    $offset: 15px;

                    display: flex;
                    align-items: flex-start;
                    justify-content: space-around;

                    width: 100%;

                    padding: $offset;

                    &.is-loading {
                        .ui.loader {
                            transform: translateX(-$offset) translateY(-50%);
                        }
                    }

                    .dash-item-name {
                        .dash-name {
                            width: 100%;

                            font-size: 20px;
                        }
                    }

                    .dash-detail-container {
                        width: 100%;

                        label {
                            font-size: 8.5px;
                        }
                    }

                    &.is-v2 {
                        justify-content: flex-start;
                    }
                }

                &.tasks-container {
                    .has-sub-item {
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        &.is-v2 {
                            justify-content: flex-start;
                        }
                    }
                }

                &.borrowers-container {
                    .dash-item {
                        .dash-item-name {
                            width: 100%;
                        }
                    }

                    .dash-detail-container {
                        margin-top: -20px;

                        .dash-detail {
                            display: none;

                            &.is-clickable {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(tablet-landscape) {
    .dashboard-container {
        .section-container {
            flex-direction: column;

            .tasks-container {
                flex-direction: row;
            }

            .section-column {
                &:first-child,
                &:last-child {
                    margin-right: 10px;
                }

                &.tasks-container {
                    width: 100%;

                    margin-right: 0;

                    .has-sub-item {
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        &.is-v2 {
                            justify-content: flex-start;
                        }
                    }
                }

                &.borrowers-container {
                    width: 100%;

                    .dash-detail-container {
                        margin-top: -20px;
                    }
                }
            }
        }
    }
}

@include respond-to(tiny) {
    .dashboard-container {
        .section-container {
            .section-column {
                &.tasks-container {
                    .dash-item {
                        &.is-clickable {
                            min-height: 120px;

                            .completed-icon {
                                min-width: 45px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile) {
    .dashboard-container {
        padding: 0 map-get($container-padding, mobile-horizontal);
    }
}

//TODO: Uncomment or remove depending on design feedback
//@include respond-to(tiny) {
//    .dashboard-container {
//        .section-container {
//            .section-column {
//                &.tasks-container {
//                    .dash-item {
//                        &.is-loading {
//                            .ui.loader {
//                                top: unset; bottom: 0;
//                                transform: translateX(-3px) translateY(-5px);
//
//                                &,
//                                &:before,
//                                &:after {
//                                    @include resize (24px);
//                                }
//                            }
//                        }
//                    }
//                }
//            }
//        }
//    }
//}

// accounts for Galaxy S5
@media only screen and (max-width: 360px) {
    .header-button-link {
        font-size: 11px !important;
    }
}
