@import 'src/styles/toolkit';

.page-header {
    margin: 0 map-get($container-padding, horizontal) 55px;
    border-bottom: 1px solid map-get($jmj-grey, 400);

    &.pre-application {
        border: none;
    }

    &.has-sub-label {
        margin-bottom: 0;
    }

    .logo {
        height: 58px;
    }

    .header-menu-button {
        display: none;
    }

    .page-controls {
        @include flex-center-end();
    }
}

.header-button-link {
    @include button-link-tab();
}

@include respond-to(tablet) {
    .page-header {
        margin: 0 map-get($container-padding, tablet-horizontal) 55px;
    }
}


@include respond-to(mobile) {
    .page-header {
        @include flex-start-spaced();

        margin: 0 map-get($container-padding, mobile-horizontal) 35px;

        .logo {
            height: 42px;

            margin-bottom: $offset;
        }

        .page-controls {
            display: none;
        }

        .header-menu-button {
            display: block;

            margin-top: 11px;

            &,
            &.basic,
            &.basic.inverted {
                box-shadow: none !important;
            }
        }

        .sidebar {
            z-index: 1001 !important;

            .item {
                padding: $offset;
                border-bottom: 1px solid map-get($jmj-grey, 400);

                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
            }

            .page-controls {
                @include flex-end();
                flex-direction: column;

                .header-button-link {
                    @include button-link-tab(0, $offset);

                    @include flex-center-start();
                    width: 100%;

                    box-shadow: 0 1px 0 0 map-get($jmj-grey, 400);

                    &.inverted {
                        &.is-active {
                            $active-blue: map-get($jmj-blue, 800);

                            box-shadow: 0 1px 0 0 $active-blue;

                            color: $active-blue;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile-wide-landscape) {
    .page-header {
        @include flex-start-spaced();

        margin: 0 map-get($container-padding, mobile-horizontal) 35px;

        .logo {
            height: 42px;

            margin-bottom: $offset;
        }

        .page-controls {
            display: none;
        }

        .header-menu-button {
            display: block;

            margin-top: 11px;

            &,
            &.basic,
            &.basic.inverted {
                box-shadow: none !important;
            }
        }

        .sidebar {
            z-index: 1001 !important;

            .item {
                padding: $offset;
                border-bottom: 1px solid map-get($jmj-grey, 400);

                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
            }

            .page-controls {
                @include flex-end();
                flex-direction: column;

                .header-button-link {
                    @include button-link-tab(0, $offset);

                    @include flex-center-start();
                    width: 100%;

                    box-shadow: 0 1px 0 0 map-get($jmj-grey, 400);

                    &.inverted {
                        &.is-active {
                            $active-blue: map-get($jmj-blue, 800);

                            box-shadow: 0 1px 0 0 $active-blue;

                            color: $active-blue;
                        }
                    }
                }
            }
        }
    }
}
