@import "src/styles/toolkit";

.review-and-submit-container {
    $section-margin: 0 72px 0 26px;
    $column-1-width: 31.37%;
    $column-2-width: 26.5%;
    $column-3-width: 25%;
    $column-4-width: calc(100% - (#{$column-1-width} + #{$column-2-width} + #{$column-3-width}));
    $column-4-offset: calc(100% - #{$column-4-width});

    width: 867px;

    .review-section-container {
        .summary-section {
            padding-bottom: 40px;

            &.is-subsection {
                border: none;
                margin-top: 25px;
                padding: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .summary-section-title {
                line-height: 28px;

                margin: 0 0 19px;

                color: map-get($jmj-blue, 700);
                font-size: 24px;
                font-weight: normal;
            }

            .details-container {
                .detail {
                    &:nth-child(4n+1) {
                        width: $column-1-width;
                    }

                    &:nth-child(4n+2) {
                        width: $column-2-width;
                    }

                    &:nth-child(4n+3) {
                        width: $column-3-width;
                    }

                    &:nth-child(4n) {
                        width: $column-4-width;
                    }
                }
            }

            &.summary-table {
                margin-top: 25px;
                border: none;

                .ui {
                    &.table {
                        width: 100%;

                        thead {
                            tr {
                                th {
                                    line-height: 18px;

                                    padding-bottom: 25px;

                                    color: map-get($jmj-grey, 600);
                                    font-size: 13px;
                                }
                            }
                        }

                        tbody {
                            tr {
                                border-bottom: none;

                                td {
                                    padding-top: 15px;
                                    padding-bottom: 7px;

                                    color: map-get($jmj-grey, 450);
                                }

                                &:not(:first-of-type) {
                                    td {
                                        padding-top: 7px;
                                        border-top: none;
                                    }
                                }
                            }
                        }
                    }
                }

                .summary-section-mobile-container {
                    display: none;
                }

                .empty-table-container {
                    @include flex-center-start();

                    .summary-section-title {
                        margin-top: $offset;

                        color: map-get($jmj-grey, 450);
                    }
                }

                .summary-section-footer {
                    margin-top: 57px;
                }
            }
        }

        .summary-section-footer {
            .alternate-button {
                margin-right: 46px;
            }
        }
    }

    .review-footer {
        @include flex-center-end();

        margin: $section-margin;
        border-top: 1px solid map-get($jmj-grey, 250);

        .error-container {
            @include flex-center();
            width: calc(100% - 167px);

            margin-top: 55px;

            span {
                color: map-get($jmj-accent, orange);
            }
        }

        .submit-button {
            width: 167px;

            margin-top: 55px;
            
            &:hover {
                background-color: map-get($jmj-blue, 600)
            }
        }
    }
}

@include respond-to(tablet) {
    .review-and-submit-container {
        width: 100%;

        .review-footer {
            width: 100%;

            margin: 0;
        }
    }
}

@include respond-to(tablet-portrait) {
    .review-and-submit-container {
        .review-section-container {
            .summary-section {
                .details-container {
                    .detail {
                        width: percentage(1/3) !important;
                    }
                }

                &.summary-table {
                    padding: 0;

                    .table {
                        display: none;
                    }

                    .summary-section-mobile-container {
                        display: block;

                        .summary-section {
                            padding-bottom: 4px;
                        }
                    }

                    .summary-section-footer {
                        margin-top: $offset-double;
                        padding-right: 26px;
                    }
                }
            }

            .summary-section-footer {
                margin-top: 20px;

                .alternate-button {
                    margin-right: 0;
                }
            }
        }
    }
}

@include respond-to(mobile) {
    .review-and-submit-container {
        br {
            display: none
        }

        .review-section-container {
            .summary-section {
                .details-container {
                    .detail {
                        width: 50% !important;
                    }
                }

                &.summary-table {
                    padding: 0;

                    .table {
                        display: none;
                    }

                    .summary-section-mobile-container {
                        display: block;

                        .summary-section {
                            padding-bottom: 4px;
                        }
                    }

                    .summary-section-footer {
                        margin-top: $offset-double;
                    }
                }
            }

            .summary-section-footer {
                margin-top: 20px;

                .alternate-button {
                    margin-right: 0;
                }
            }
        }
    }
}

@include respond-to(mobile-portrait) {
    .review-and-submit-container {
        .review-section-container {
            .summary-section {
                .details-container {
                    .detail {
                        flex: 1;
                        min-width: 100px;

                        margin-right: 10px;
                    }
                }

                .summary-section-footer {
                    button {
                        width: 100%;

                        margin: 0;
                    }
                }
            }
        }

        .review-footer {
            flex-direction: column;
            width: 100%;

            margin: 0;

            .error-container {
                width: 100%;

                margin: 35px 0 0;
            }

            .submit-button {
                width: 100%;

                margin: 50px 0;
            }
        }
    }
}

@include respond-to(mobile-landscape) {
    .review-and-submit-container {
        .review-section-container {
            .summary-section {
                .details-container {
                    .detail {
                        width: percentage(1/3) !important;
                    }
                }

                &.summary-table {
                    .summary-section-footer {
                        padding-right: 26px;
                    }
                }
            }
        }
    }
}

@include respond-to(tiny) {
    .review-and-submit-container {
        .review-section-container {
            .summary-section {
                .details-container {
                    .detail {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

@include respond-to(tiny-landscape) {
    .review-and-submit-container {
        .review-section-container {
            .summary-section {
                &.summary-table {
                    .summary-section-footer {
                        padding-right: 26px;
                    }
                }
            }
        }
    }
}
